const imageUpload = document.getElementById('uploadImages');
const imagePreview = document.getElementById('preview-images');

imageUpload.addEventListener('change', function() {
    // Clear any existing images
    imagePreview.innerHTML = '';

    let files = imageUpload.files;

    if (files.length > 3) {
        imageUpload.value = '';
        document.getElementById('error-max').style.display = 'block';
        document.getElementById('upload-files').classList.remove('--active')
        document.getElementById('big-upload-images').style.display = 'block';
        return;
    } else {
        document.getElementById('error-max').style.display = 'none';
        document.getElementById('big-upload-images').classList.add('--more');
    }

    for (let i = 0; i < files.length; i++) {
        const parentDiv = document.createElement('div');
        parentDiv.classList.add('_item');

        const parentDivLoader = document.createElement('div');
        parentDivLoader.classList.add('_loader');

        parentDiv.appendChild(parentDivLoader);
        imagePreview.appendChild(parentDiv);
    }

    // Loop through each selected file
    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();

            // When the file is read, display the image preview
            reader.onload = function(e) {
                const imgElement = document.createElement('img');
                imgElement.src = e.target.result; // Set the image source to the file data

                imagePreview.children[i].appendChild(imgElement); // Add parent div to the preview container
                imagePreview.children[i].classList.add('--loaded');
            };

            reader.readAsDataURL(file); // Read the image file

            // document.getElementById('big-upload-images').classList.remove('--active');
            document.getElementById('upload-files').classList.add('--active');
        } else {
            imageUpload.value = ''; // Clear the input if the file is not an image
        }
    }

    // const containerElem = document.createElement('div');
    // containerElem.classList.add('_item');
    // containerElem.classList.add('--upload');
    // const uploadLabel = document.createElement('label');
    // uploadLabel.setAttribute('for', 'uploadImages'); // This links to the file input
    // uploadLabel.setAttribute('id', 'big-upload-images');
    // uploadLabel.classList.add('upload-button', '--active');
    // uploadLabel.innerHTML = '<span>Meer kiezen</span>';
    // containerElem.appendChild(uploadLabel);
    // imagePreview.appendChild(containerElem);
});


//Upload button

document.getElementById('upload-files').addEventListener('click', function() {
    this.classList.add('--loading');

    var customerKey = document.getElementById('uploadCustomerKey').value;
    var input = document.getElementById('uploadImages');
    var files = input.files; // Get the selected files
    var formData = new FormData(); // Create FormData object to hold file data

    // Append each selected file to the formData object
    for (var i = 0; i < files.length; i++) {
        formData.append('images[]', files[i]);
    }

    console.log(customerKey);

    formData.append('uploadCustomerKey', customerKey);

    // AJAX request to upload the images
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/upload', true);

    xhr.onload = function () {
        if (xhr.status === 200) {
            console.log('Images uploaded successfully');
            imageUpload.value = '';

            document.getElementById('upload-files').classList.remove('--loading');
            document.getElementById('error-max').style.display = 'none';
            document.getElementById('upload-files').classList.remove('--active')
            document.getElementById('big-upload-images').classList.remove('--more');
            document.getElementById('preview-images').innerHTML = '';

            document.body.classList.toggle('--pop-up');
            document.getElementById('upload-pop-up').classList.toggle('--active');
            document.getElementById('alert-uploaded').classList.toggle('--active');
            // Optional: handle response (e.g., display a success message)

            setTimeout(function(){
                location.reload();
            },1000)
        } else {
            console.error('Error uploading images');
        }
    };

    xhr.send(formData); // Send the images via AJAX
});


// Remove button

// const fileInput = document.getElementById('uploadImages');

// function removeFile() {
//     // Check if there are at least 2 files
//     if (fileInput.files.length >= 2) {
//         // Create a new DataTransfer object to hold the filtered files
//         const dataTransfer = new DataTransfer();

//         // Loop through the FileList and exclude the second file (index 1)
//         Array.from(fileInput.files).forEach((file, index) => {
//             if (index !== 1) { // Skip the second file
//                 dataTransfer.items.add(file);
//             }
//         });

//         // Assign the new FileList to the file input
//         fileInput.files = dataTransfer.files;
        
//         console.log('Second file removed.');
//     } else {
//         console.log('Less than 2 files selected.');
//     }
// }

// // Example: Call removeSecondFile after files are selected
// fileInput.addEventListener('change', function() {
//     removeSecondFile();
// });