window.onload = function() {
    if (document.getElementById("countdown")) {
        let targetDate = false;
        const getDate = document.getElementById("countdown").getAttribute('data-date');

        if (getDate) {
            targetDate = new Date(document.getElementById("countdown").getAttribute('data-date'));
        }

        if (targetDate) {
            const countdown = setInterval(function() {
                // Get the current date and time
                const now = new Date().getTime();

                // Calculate the difference between the current time and the target date
                const timeRemaining = targetDate - now;

                // Calculate days, hours, minutes, and seconds
                const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
                const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

                // Ensure two-digit formatting using padStart()
                document.getElementById("days").textContent = String(days).padStart(2, '0');
                document.getElementById("hours").textContent = String(hours).padStart(2, '0');
                document.getElementById("minutes").textContent = String(minutes).padStart(2, '0');
                document.getElementById("seconds").textContent = String(seconds).padStart(2, '0');

                if (timeRemaining < 0) {
                    clearInterval(countdown);
                    document.getElementById("countdown").innerHTML = "Countdown Finished!";
                }
            }, 1000);
        }
    }   
};