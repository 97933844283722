document.addEventListener("DOMContentLoaded", function () {
    const lazyImages = document.querySelectorAll('.lazy');

    // Create Intersection Observer
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const img = entry.target;
                img.src = img.dataset.src; // Set the actual image source from data-src
                img.onload = () => {
                    img.parentElement.classList.add('loaded'); // Add 'loaded' class when image is loaded
                    img.parentElement.style.height = img.height + 'px'; // Add 'loaded' class when image is loaded
                    
                    setTimeout(function(){
                        img.parentElement.style.height = 'auto';
                    }, 200);
                };
                observer.unobserve(img); // Stop observing the image after it is loaded
            }
        });
    });

    // Observe each lazy image
    lazyImages.forEach(img => {
        observer.observe(img);
    });
});