import '../scss/styles.scss';

// Helpers
import './helpers/onclick.js';
import './helpers/lazyload.js';

// Components
import './components/countdown.js';

// Partials
import './partials/loadscreen.js';
import './partials/upload-files.js';